element::-webkit-scrollbar {
  width: 0 !important;
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0;
  box-sizing: border-box;
  background-color: #0d406a;
  color: #f4f4f4;
  font-family: "Roboto", sans-serif;
}

.IDL__Logo {
  width: 7rem;
}

.TV__Logo {
  width: 7rem;
}

.Smartbar__Logo {
  font-family: "Gotham";
}

.App__room {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  flex-wrap: wrap;
  flex-direction: row;
}

.App__centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 200px;
  margin: 10px; /* Add some margin between items */
}

.App__Room--Name {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.li__color {
  color: white;
}

.App__input {
  margin-top: 1rem;
  outline: none;
  border: 2px solid #1b171c; /* ID Logistics blue color */
  border-radius: 5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.App__button {
  margin-top: 1rem;
  border-radius: 5px;
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 2px solid #1b171c;
  outline: none;
  transition: 1s ease-in-out;
  color: #ef3342;
}

.App__button:hover {
  transition: 1s ease-in-out;
  background: #fefefe;
}

.App__button:hover svg {
  stroke-dashoffset: -480;
}

.App__button span {
  color: white;
  font-size: 18px;
  font-weight: 100;
}

.sign__out {
  display: inline-block;
  position: sticky;
  top: 0;
}

.tv__guide {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.9rem;
  border-radius: 5px;
  width: 6rem;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 2px solid #1b171c;
  outline: none;
  transition: 1s ease-in-out;
  color: #fefefe;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.tv__guide:hover {
  transition: 1s ease-in-out;
  background: #ef3342;
}

.room__div {
  height: auto;
  max-height: 70vh;
  border: 3px solid black;
  border-radius: 0.5rem;
  width: 30vw;
  margin-left: 10rem;
  text-align: center;
}

.room__flex {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.room__button {
  width: 5rem;
  height: 3rem;
  margin-left: 1rem;
}

.room__row {
  display: flex;
  margin-bottom: 1rem;
}

.online__flex {
  display: flex;
  margin-top: 1.5rem;
}

.online__counter {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  display: inline-block;
  margin-right: 5px;
  margin-left: 1rem;
}

.note {
  color: #ffd700;
}

h3 {
  border-bottom: 3px solid black;
}

h4 {
  text-decoration: underline;
  margin-top: 1.5rem;
}
