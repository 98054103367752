.guide__body {
  display: flex;
  justify-content: center;
}

.guide__title {
  font-size: 2rem;
  color: white;
  text-decoration: underline;
}

.grid__box {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
}

.guide__container--center {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.grid--1 {
  margin-left: 5rem;
}

.grid--2 {
  margin-right: 10rem;
}

.guide__tv--icon {
  width: 10rem;
  padding: 0.5rem;
}

.guide__phone--icon {
  width: 6.5rem;
  margin-left: 2rem;
}

.guide__tv--h1 {
  margin-top: 1.5rem;
  color: #1b171c;
}

.guide__tv--h1:hover {
  text-decoration: underline;
}

.guide__phone--h1 {
  margin-left: 1rem;
  margin-top: 1.5rem;
  color: #1b171c;
}

.guide__phone--h1:hover {
  text-decoration: underline;
}

.guide__abonnement--icon {
  height: 6.5rem;
  margin-left: 4rem;
}

.guide__laptop--icon {
  height: 6.5rem;
}
