.div__center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
}

.div__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
}

.card__tv__image {
  width: 15rem;
}
