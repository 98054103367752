* {
  font-family: "Titillium Web", sans-serif;
}

h1 {
  font-size: 2.5rem;
}

.color__black {
  color: white;
}

.tv__icon {
  width: 4rem;
}

.guide__container {
  margin-top: 1.5rem;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.guide__flex {
  display: flex;
  margin: 2rem;
}

.guide__p {
  margin-right: 2rem;
  font-size: 1.1rem;
}

.calman__image {
  width: 50vw;
  border-radius: 1rem;
}

.guide__selector--title {
  margin-top: 3.5rem;
  text-align: center;
  text-decoration: underline;
  color: white;
}

.guide__selector--div {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.tv__flex--container {
  display: flex;
  justify-content: center;
}

.tv__image {
  border-radius: 1rem;
  height: 6rem;
  width: 10rem;
  margin: 1rem;
  border-radius: 1rem;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
}

.tv__image:hover {
  transform: scale(1.3);
}
