.auth__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.h4__signin {
  font-family: "Roboto", sans-serif;
  margin-bottom: 1rem;
}

.signin__google {
  height: 2rem;
  font-size: 0.8rem;
  width: 10rem;
  margin-bottom: 1rem;
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
}

.signin__google:hover {
  color: #000000;
  background-color: #ffffff;
}

.disclaimer {
  text-align: center;
  text-decoration: underline;
  font-style: italic;
}

.auth__flex--row {
  align-items: center;
  display: flex;
  margin-bottom: 5rem;
}

.Logo {
  width: 7rem;
  height: 5.2rem;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
