h1 {
  color: #ef3342;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

h2 {
  color: #ef3342;
}

.lg__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

li {
  color: #ef3342;
  margin-bottom: 0.5rem;
}

.li__color {
  color: white;
}

.p__container {
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.row__container {
  display: flex;
  flex-direction: row;
}

.row__column {
  justify-content: center;
}

.row__h4 {
  display: flex;
  justify-content: center;
}

.lg__box {
  width: 30rem;
  margin-left: 3rem;
}

.button__guides {
  margin: 1rem;
  margin-top: 5rem;
  font-size: 1.3rem;
  border-radius: 5px;
  width: 9rem;
  height: 6rem;
  cursor: pointer;
  background: transparent;
  background: #1b171c;

  border: 2px solid #1b171c;
  outline: none;
  transition: 1s ease-in-out;
  color: #ef3342;
}

.button__guides:hover {
  transition: 0.6s ease-in-out;
  background: #1b171c;
}

.animation {
  transition: all 0.2s ease-in-out;
}

.animation:hover {
  transform: scale(1.3);
}

.back__arrow {
  width: 2rem;
  color: white;
}

.back__container {
  position: absolute;
}

.back__button {
  border-radius: 5px;
  width: 6rem;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 2px solid #1b171c;
  outline: none;
  transition: 1s ease-in-out;
  color: #ef3342;
}
