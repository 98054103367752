h1 {
  color: #ef3342;
}

h2 {
  color: #ef3342;
}

.color__white {
  color: white;
}

.manual__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
}
