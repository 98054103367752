h1 {
  color: #ef3342;
}

h2 {
  color: #ef3342;
}

.samsung__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

li {
  color: #ef3342;
}

.p__container {
  line-height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.row__container {
  display: flex;
  flex-direction: row;
}

.samsung__box {
  width: 30rem;
  margin-left: 3rem;
}

.list-decimal {
  list-style-type: decimal;
}
