body {
  overflow: hidden;
}

.chat {
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.chat-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
  width: 90%;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid #3b5998;
}
.header {
  background-color: #3b5998;
  color: white;
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}
.messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 90%;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 77%;
}

.message {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  overflow-wrap: y;
}

.user {
  font-weight: bold;
  margin-right: 10px;
  color: #333;
}

.new__message__form {
  display: flex;
  margin-top: 2rem;
  bottom: 1.5rem;
}

.new__message__input {
  outline: none;
  border: 2px solid #1b171c; /* ID Logistics blue color */
  border-radius: 5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding: 10px;
  height: 60px;
  width: 30rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  .new__message__input {
    width: auto;
  }
}

.Chat__message {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 0.7rem;
  align-self: flex-start;
  margin-left: 30%;
  word-wrap: break-word;
}

.Chat__room--title {
  color: #ef3342;
}

.signOut--sendMessage__button {
  border-radius: 5px;
  width: 6rem;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 2px solid #1b171c;
  outline: none;
  transition: 1s ease-in-out;
  color: #ef3342;
}

.signOut--sendMessage__button:hover {
  transition: 1s ease-in-out;
  background: #fefefe;
}

.send__message__container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
}

.profile__picture {
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
}

.flex-end {
  align-self: flex-end;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  width: 6rem; /* Equivalent to w-24 */
  border: 2px solid black; /* Equivalent to border-2 border-gray-900 */
  outline: none; /* Equivalent to outline-none */
}

.hidden {
  display: none;
}

.chat-image {
  height: 16rem; /* Equivalent to h-64 */
  margin-left: 0.5rem; /* Equivalent to ml-2 */
  object-fit: contain; /* Equivalent to object-contain */
  cursor: pointer; /* Equivalent to cursor-pointer */
  border-radius: 1rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  display: block;
  margin: auto;
}
